import React from "react";
import posthog from "posthog-js";

// import EyeMedLogo from "../assets/insurance-eyemed.png";
// import VSPLogo from "../assets/insurance-vsp.jpg";
// import MESLogo from "../assets/insurance-mesvision.jpg";
// import SuperiorLogo from "../assets/insurance-superior.jpg";
// import MedicareLogo from "../assets/insurance-medicare.jpg";
// import HumanaLogo from "../assets/insurance-humana.jpg";
import Layout from "../components/layout";

export default function Insurance() {
  posthog.capture("$insurance");
  return (
    <Layout>
      <main
        id="content"
        role="main"
        className="main sv-landing full-page-content"
      >
        <section className="overflow-hidden">
          <h2 className="md-h2 caps pl4 mt4">
            <div>Insurance</div>
          </h2>
          <div className="mb4 pl4 pr4 mt4">
            <p>
              We accept VSP. All other vision plans are considered as
              out-of-network.
            </p>
          </div>
        </section>
      </main>
    </Layout>
  );
}
